@import '../../global/styles/lib';

$footer-margin-bottom: 1.5rem;

@mixin footer-icon {
	content: "";
	@include absolute-full;
	z-index: 0;
	opacity: 0.35;
	background-image: url('../../global/images/Mascagni_circle-white.svg');
	background-position: center;
	background-size: 15rem;
	background-repeat: no-repeat;
}

// Keep footer at bottom no matter content length
html,
body {
	height: 100%;
	position: relative;
}

#container {
	min-height: 100vh;
	position: relative;
	display: block;
	overflow: hidden;
} // #container

#theme-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: auto;
	color: $masc-gray-dark;
	padding: 0 2rem;
	z-index: 0;
	overflow: visible;
	background: $masc-gray-light;

	.banner-container {
		@include absolute-full;
		overflow: hidden;	
		.banner {
			@include absolute-full;
		} // .banner

	} // .banner-container

	a {
		&:not(.button) {
			color: $masc-gray-dark !important;
		} // &:not(.button)
	} // a

	&::before {
		display: none;
		@include footer-icon;
		z-index: 1;
	} // &::before

	.content {
		position: relative;

		&::before {
			@include footer-icon;
			z-index: -1;
		} // &::before
	
	} // .content

	.constraint {
		position: relative;
		max-width: 70rem;
		margin: auto;
		z-index: 1;
	} // .constraint

	.case {
		display: flex;
		flex-wrap: wrap;
		padding-top: 2rem;
		
		.left, .right {
			width: 100%;
			text-align: center;
		} // .left, .right

		.left {
			margin-bottom: 2rem;

			.sep {
				display: none;
			} // .sep

			.title {
				font-weight: 700;
				margin-bottom: $footer-margin-bottom;
				font-size: 1.05rem;
			} // .title

			.address {
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-bottom: $footer-margin-bottom;

				.view-map {
					font-size: 0.85rem;
					font-weight: 700;
					margin-left: 0.5rem;
					text-transform: uppercase;
					cursor: pointer;
					margin-top: 0.5rem;
				} // .view-map
			} // .address
			
			.phone-email {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.phone {
					font-weight: 700;
					margin-right: 0.5rem;
				} // .phone

				.email {
					margin-left: 0.5rem;
					a {
						#{$hovfoc} {
							text-decoration: underline;
						} // hover focus
					} // a

				} // .email

			} // .phone-email
		
		} // .left
		
		.right {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;

			.social-icons {
				margin-bottom: 1rem;
				.icon {
					display: inline-block;
					width: 2rem;

					&:not(:last-of-type) {
						margin-right: 1rem;
					}

					> a {
						opacity: .7;
						transition: opacity $hover-transition;

						&:hover { opacity: 1; }
						> svg {
							color: inherit;
							fill: currentColor;
						}
					} // > a
				} // .icon
			} // .social-icons

			.menu {
				.menu-item {
					a {
						display: inline-block;
					} // a
					&::after {
						content: "|";
					} // &::after
					&:last-child {
						&::after {
							display: none;
						} // &::after
					} // &:last-child
				} // .menu-item

			} // .menu

			.button-group {
				width: 100%;
				text-align: center;
				.button {
					font-size: 0.85rem;
					background: $white;
					font-weight: 700;
					border: solid 2px $masc-blue-dark;
					padding: 0.5rem 1rem;
					color: $masc-blue-dark;

					#{$hovfoc} {
						background: $masc-blue-dark;
						color: $white;
						border-color: $white;
					} // hover focus

				} // .button

			} // .button-group
		
		} // .right

	} // .case

	.copyright-copy {
		font-size: 0.85rem;
		margin-top: 2rem;
		a {
			font-weight: 700;
		} // a
	} // .copyright

	.map-embed {
		display: none;
		@include absolute-full;
		height: auto;
		transform: translateY(-100%);
		@include footer-background-color-blur;
		text-align: center;

		.close-container {
			position: absolute;
			top: 0;
			right: 50%;
			width: 100%;
			height: 100%;
			@include content-constraint;
			transform: translateX(50%);
			pointer-events: none;

			.close {
				position: absolute;
				pointer-events: auto;
				@include content-constraint;
				cursor: pointer;
				top: 0;
				right: 0;
				height: 2rem;
				width: 2rem;
				background: $black;
				border-radius: 50%;
				margin-top: 0.5rem;
				margin-right: 0.5rem;
				transform: scale(1);
				transition: all $hover-transition;
	
				svg {
					position: relative;
					transform: scale(0.6);
					fill: $white;
				}
	
				#{$hovfoc} {
					transform: scale(1.15);
	
				} // hover focus
	
			} // .close

		} // .close-container
		
		iframe {
			@include content-constraint;
			//position: relative;
			//z-index: 1;
			margin: auto;
			width: 100%;
			padding: 1rem;
			height: 25rem;
		} // iframe
	} // .map-embed

	@include breakpoint(medium up) {
		.case {
			.left {
				.title {
					font-size: 1.5rem;
				} // .title

				.sep {
					display: block;
				} // .sep

				.address {
					flex-direction: row;
					font-size: 1.25rem;
					.view-map {
						margin-top: 0;
					} // .view-map

				} // .address

				.phone-email {
					flex-direction: row;
				} // .phone-email

			} // .left
		
		} // .case

	} // medium up

	@include breakpoint(large up) {
		&::before {
			display: block;
		} // &::before
	
		.content {
			&::before {
				display: none;
			} // &::before
		
		} // .content
		
		.case {
			padding-top: 5.65rem;

			.left,
			.right {
				width: 50%;
			} // .left, .right

			.left {
				text-align: left;
				margin-bottom: 0;

				.title {
					font-size: unset;
				} // .title

				.address {
					justify-content: flex-start;
					font-size: unset;
				} // .address

				.phone-email {
					justify-content: flex-start;
					font-size: unset;
				} // .phone-email

			} // .left

			.right {
				flex-direction: row;
				justify-content: flex-end;

				.button-group {
					text-align: right;
					
				} // .button-group

			} // .right

		} // .case

	} // large up
	
} // #theme-footer