/**
 * Imports libraries of settings, mixins, functions, etc. that should be available
 * to all stylesheets. MUST NOT OUTPUT STYLES.
 *
 * @author    <corey@chatmandesign.com>
 * @since     3.0.0
 */
/**
 * Breakpoint range variables for the lock mixin.
 * Convert the breakpoints map into range variables like we had in ZF5. The
 * magic number at the end is the difference between the breakpoint we define in
 * $breakpoints and the actual max-width value spit out by the breakpoints()
 * mixin when we give it an "only" argument (to avoid overlap. See the ZF5 media
 *  query range variables for reference.)
 *
 * $small-range is a special snowflake. The whole purpose of the lock mixin is
 * to let you set a property to a fixed value up to a certain breakpoint, then
 * have that value increase in a linear fashion until you hit another
 * breakpoint, and then keep the value fixed again for the rest of the way. The
 * way the breakpoints are defined, the small range would be 0px - 640px. But
 * we're not planning on supporting devices smaller than 320px, so it's far more
 * useful for the small range to be 320px to 640px. That way, the $min-value you
 * pass to the lock mixin is anchored to the smallest device width we support,
 * and the $max-value is anchored to the breakpoint between small and medium
 * ranges.
 *
 * @author   <dustin@chatmandesign.com>
 */
/**
 * Sass Mixins
 *
 * @package   seedyjoints
 * @since     1.0
 */
/**
 * Extend element's background to left and right edges of viewport.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 *
 * @param string $position  Any valid CSS position value, or NULL/FALSE to not set
 *                          position; but note the element must create a positioning
 *                          context for the bleed effect to work. Default: relative.
 * @param string $direction Direction the bleed will extend in -- left, right, both.
 *                          Deafult: both.
 */
/**
 * Lock (Fluid Type Mixin -- CD Remix)
 * Created 10/11/16 @ 02:19:30 PM
 *
 * Original from here:
 * http://madebymike.com.au/writing/precise-control-responsive-typography/
 *
 * This is a modified version of the above mixin that takes a single SCSS list
 * instead of two variables for min and max VW. This makes it easier to use with
 * the existing Foundation range variables in _settings.scss. You can of course
 * still pass a plain list e.g. "(20rem, 40rem)" as the argument for the range
 * parameter if you need a custom range.
 *
 * @param      $properties A space separated list of CSS properties
 * @param      $vw-range A SCSS list defining a viewport width range like
 *             "(20em, 40em)".
 * @param      $min-value A minimum property value in px/em/rem
 * @param      $max-value A maximum property value in px/em/rem
 * @author     Chatman Design / Dustin Paluch <dustin@chatmandesign.com>
 */
/**
 * List items inline, separated by pipes by default.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 */
/**
 * Transition property with vendor prefixes (DEPRECATED)
 *
 * DEPRECATED now that we use autoprefixer. Just use the standard CSS property.
 *
 * I totally rewrote this to be cleaner and easier to use. You'll need to be using
 * Sass 3.2+ for these to work. Thanks to @anthonyshort for the inspiration on these.
 *
 * USAGE: @include transition(<property> <time> <easing>);
 */
/**
 * CSS3 Gradients with vendor prefixes
 *
 * Be careful with these since they can really slow down your CSS. Don't overdo it.
 */
/* @include css-gradient(#dfdfdf,#f8f8f8); */
/**
 * Foundation for Sites
 * Version 6.6.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
html,
body {
  height: 100%;
  position: relative;
}

#container {
  min-height: 100vh;
  position: relative;
  display: block;
  overflow: hidden;
}

#theme-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  color: #56565a;
  padding: 0 2rem;
  z-index: 0;
  overflow: visible;
  background: #ddddde;
}
#theme-footer .banner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#theme-footer .banner-container .banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#theme-footer a:not(.button) {
  color: #56565a !important;
}
#theme-footer::before {
  display: none;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.35;
  background-image: url("../../global/images/Mascagni_circle-white.svg");
  background-position: center;
  background-size: 15rem;
  background-repeat: no-repeat;
  z-index: 1;
}
#theme-footer .content {
  position: relative;
}
#theme-footer .content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.35;
  background-image: url("../../global/images/Mascagni_circle-white.svg");
  background-position: center;
  background-size: 15rem;
  background-repeat: no-repeat;
  z-index: -1;
}
#theme-footer .constraint {
  position: relative;
  max-width: 70rem;
  margin: auto;
  z-index: 1;
}
#theme-footer .case {
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
}
#theme-footer .case .left, #theme-footer .case .right {
  width: 100%;
  text-align: center;
}
#theme-footer .case .left {
  margin-bottom: 2rem;
}
#theme-footer .case .left .sep {
  display: none;
}
#theme-footer .case .left .title {
  font-weight: 700;
  margin-bottom: 1.5rem;
  font-size: 1.05rem;
}
#theme-footer .case .left .address {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.5rem;
}
#theme-footer .case .left .address .view-map {
  font-size: 0.85rem;
  font-weight: 700;
  margin-left: 0.5rem;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 0.5rem;
}
#theme-footer .case .left .phone-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#theme-footer .case .left .phone-email .phone {
  font-weight: 700;
  margin-right: 0.5rem;
}
#theme-footer .case .left .phone-email .email {
  margin-left: 0.5rem;
}
#theme-footer .case .left .phone-email .email a:hover, #theme-footer .case .left .phone-email .email a:focus {
  text-decoration: underline;
}
#theme-footer .case .right {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
#theme-footer .case .right .social-icons {
  margin-bottom: 1rem;
}
#theme-footer .case .right .social-icons .icon {
  display: inline-block;
  width: 2rem;
}
#theme-footer .case .right .social-icons .icon:not(:last-of-type) {
  margin-right: 1rem;
}
#theme-footer .case .right .social-icons .icon > a {
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}
#theme-footer .case .right .social-icons .icon > a:hover {
  opacity: 1;
}
#theme-footer .case .right .social-icons .icon > a > svg {
  color: inherit;
  fill: currentColor;
}
#theme-footer .case .right .menu .menu-item a {
  display: inline-block;
}
#theme-footer .case .right .menu .menu-item::after {
  content: "|";
}
#theme-footer .case .right .menu .menu-item:last-child::after {
  display: none;
}
#theme-footer .case .right .button-group {
  width: 100%;
  text-align: center;
}
#theme-footer .case .right .button-group .button {
  font-size: 0.85rem;
  background: #fefefe;
  font-weight: 700;
  border: solid 2px #0067ac;
  padding: 0.5rem 1rem;
  color: #0067ac;
}
#theme-footer .case .right .button-group .button:hover, #theme-footer .case .right .button-group .button:focus {
  background: #0067ac;
  color: #fefefe;
  border-color: #fefefe;
}
#theme-footer .copyright-copy {
  font-size: 0.85rem;
  margin-top: 2rem;
}
#theme-footer .copyright-copy a {
  font-weight: 700;
}
#theme-footer .map-embed {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: auto;
  transform: translateY(-100%);
  background: rgba(221, 221, 222, 0.95);
  text-align: center;
}
@supports (backdrop-filter: blur(1px)) {
  #theme-footer .map-embed {
    background: rgba(221, 221, 222, 0.75);
    backdrop-filter: blur(4px);
  }
}
#theme-footer .map-embed .close-container {
  position: absolute;
  top: 0;
  right: 50%;
  width: 100%;
  height: 100%;
  max-width: 55rem;
  transform: translateX(50%);
  pointer-events: none;
}
#theme-footer .map-embed .close-container .close {
  position: absolute;
  pointer-events: auto;
  max-width: 55rem;
  cursor: pointer;
  top: 0;
  right: 0;
  height: 2rem;
  width: 2rem;
  background: #0a0a0a;
  border-radius: 50%;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}
#theme-footer .map-embed .close-container .close svg {
  position: relative;
  transform: scale(0.6);
  fill: #fefefe;
}
#theme-footer .map-embed .close-container .close:hover, #theme-footer .map-embed .close-container .close:focus {
  transform: scale(1.15);
}
#theme-footer .map-embed iframe {
  max-width: 55rem;
  margin: auto;
  width: 100%;
  padding: 1rem;
  height: 25rem;
}
@media print, screen and (min-width: 40em) {
  #theme-footer .case .left .title {
    font-size: 1.5rem;
  }
  #theme-footer .case .left .sep {
    display: block;
  }
  #theme-footer .case .left .address {
    flex-direction: row;
    font-size: 1.25rem;
  }
  #theme-footer .case .left .address .view-map {
    margin-top: 0;
  }
  #theme-footer .case .left .phone-email {
    flex-direction: row;
  }
}
@media print, screen and (min-width: 64em) {
  #theme-footer::before {
    display: block;
  }
  #theme-footer .content::before {
    display: none;
  }
  #theme-footer .case {
    padding-top: 5.65rem;
  }
  #theme-footer .case .left,
  #theme-footer .case .right {
    width: 50%;
  }
  #theme-footer .case .left {
    text-align: left;
    margin-bottom: 0;
  }
  #theme-footer .case .left .title {
    font-size: unset;
  }
  #theme-footer .case .left .address {
    justify-content: flex-start;
    font-size: unset;
  }
  #theme-footer .case .left .phone-email {
    justify-content: flex-start;
    font-size: unset;
  }
  #theme-footer .case .right {
    flex-direction: row;
    justify-content: flex-end;
  }
  #theme-footer .case .right .button-group {
    text-align: right;
  }
}